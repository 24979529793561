@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

$font-1: "Roboto", sans-serif;
$font-2: "Open Sans", sans-serif;

h1 {
	font-family: $font-1;
    font-size: 4rem;
    font-weight: 700;
}

h2 {
	font-family: $font-1;
    font-size: 3rem;
    font-weight: 700;
    color: $color-text-light;
}

h3 {
	font-family: $font-1;
    font-size: 2rem;
    font-weight: 600;
    color: $color-text-light;
}

h4 {
	font-family: $font-2;
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-text-accent;
}

a {
	font-family: $font-1;
    font-size: 1.2rem;
	font-weight: 300;
    color: $color-text-light;
    text-decoration: none;
    cursor: pointer;
}

p {
	font-family: $font-2;
    font-size: 1.16rem;
	font-weight: 400;
    color: $color-text-light;
}
