.hero {
	position: relative;
	display: flex;
	height: 100vh;
	min-height: 850px;
	overflow: hidden;
	background-repeat: no-repeat;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		width: 1px;
		bottom: 0;
		background: rgba(249, 20, 89, 0.4);
		left: 105px;
		z-index: 99;
	}

	.slide {
		height: 100vh;
		min-height: 850px;
		width: 100vw;
		max-width: 100vw;
		background-repeat: no-repeat;
		position: relative;

		.hero-image {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;

			img.hero-image-img {
				width: 100vw;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		.hero-container {
			flex-wrap: wrap;
			display: flex;
			padding-left: 200px;
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 2;
			padding: 15px;

			.hero-row {
				display: flex;
				flex-wrap: wrap;
				padding: 0 200px;
				width: 100%;
				padding: 100px 200px;

				.hero .slide-caption > div * {
					-webkit-animation: fadeInLeft 1.5s both;
					animation: fadeInLeft 1.5s both;
				}

				.slide-caption {
					flex: 0 0 66.666667%;
					max-width: 66.666667%;
					position: relative;
					width: 100%;

					.slide > div {
						overflow: hidden;
					}

					.hero-title {
						padding: 90px 15px;
						flex: 0 0 66.666667%;
					}

					.slide-subtitle {
						text-align: left;
						margin-left: 0;
						animation-delay: 0s;

						h4 {
							position: relative;
							margin-bottom: 0;
							display: inline-block;
							color: $color-text-accent;
							font-weight: 400;
							text-transform: uppercase;
							letter-spacing: 8px;
							margin: 0;
						}
					}

					.slide-title {
						text-align: left;
						animation-delay: 0s;

						h2 {
							margin-top: 0;
							font-size: 90px;
							font-weight: 700;
							line-height: 1.2em;
							text-transform: uppercase;
							color: $color-light-2;
							margin-bottom: 30px;
						}
					}

					.slide-btn {
						width: fit-content;
					}
				}
			}
		}
	}

	.social-links {
		left: 25px;
		width: auto;
		position: absolute;
		bottom: 15px;
		z-index: 999;

		.fa-icon {
			opacity: 0.7;
			transition: all 0.3s;

			&:hover {
				opacity: 0.9;
				color: $color-accent-2;
			}
		}

		&:before {
			content: "";
			width: 1px;
			height: 50px;
			background: rgba(249, 20, 89, 0.4);
			position: absolute;
			left: 27px;
			top: -70px;
		}

		&:after {
			color: $color-med-1;
			content: "SOCIAL";
			position: absolute;
			left: -15px;
			top: -157px;
			letter-spacing: 5px;
			transform: rotate(-90deg);
			font-family: $font-1 !important;
		}

		ul {
			list-style: none;
			padding-left: 0;
			margin: 0;

			li {
				margin: 15px;

				a {
					text-decoration: none;
					cursor: pointer;
				}
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.slide .hero-container .hero-row .slide-caption .slide-title h2 {
			font-size: 48px;
			margin-top: 20px;
		}

		.slide-subtitle h4 {
			font-size: 18px;
		}
	}

	@media only screen and (max-width: 599px) {
		.slide .hero-container .hero-row {
			padding: 100px 120px;

			.slide-caption .slide-title h2 {
				font-size: 28px;
				margin-top: 20px;
			}
		}
	}
}
