.fixed-navbar {
	position: relative;
	z-index: 5555;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;

	.header {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 99;
		border-bottom: 1px solid rgba(249, 20, 89, 0.4);

		.navigation-container {
			position: relative;
			padding: 15px;
			background-color: transparent;
			margin-bottom: 0;
			border: 0;
			border-radius: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			.navigation {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				.navbar-header {
					height: auto;

					a {
						width: 70px;
						display: inline-block;
						margin-right: 1rem;
						line-height: inherit;
						white-space: nowrap;

						&:hover {
							opacity: 0.6;
						}

						img {
							width: 100%;
						}
					}
				}
				.navigation-container-holder {
					right: -526px;
					background: #191919;
					width: 500px;
					height: 100% !important;
					padding: 65px 55px;
					position: fixed;
					top: 0;
					z-index: 99999;

					&.active {
						transition: all 0.5s;
						background: $color-dark-1;
						width: 500px;
						height: 100% !important;
						padding: 65px 55px;
						position: fixed;
						top: 0;
						right: 0;
						z-index: 99999;
						flex-basis: 100%;
						flex-grow: 1;
						align-items: center;

						button {
							background-color: $color-dark-1;
							width: 40px;
							height: 40px;
							color: $color-text-light;
							border: 1px solid $color-accent-1-rgba;
							border-radius: 50%;
							outline: none;
							position: absolute;
							left: -20px;
							top: 10px;
							z-index: 20;
							cursor: pointer;
							transition: all 0.3s;

							&:hover {
								background-color: $color-accent-2;
							}

							.fa-icon {
								transition: all 0.3s;

								&:hover {
									color: $color-text-light;
								}
							}
						}
					}

					ul.navbar-nav {
						position: relative;
						overflow-x: hidden;
						overflow-y: scroll;
						width: 100%;
						height: 100%;
						float: none;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						padding-left: 0;
						margin-bottom: 0;
						margin: 0;

						li {
							float: none;
							position: relative;
							margin-bottom: 40px;

							&.thumb-home a:after {
								content: "home";
								text-transform: uppercase;
							}

							&.thumb-about a:after {
								content: "about me";
								text-transform: uppercase;
							}

							&.thumb-education a:after {
								content: "where it began";
								text-transform: uppercase;
							}

							&.thumb-experience a:after {
								content: "where it leads";
								text-transform: uppercase;
							}

							a {
								padding: 0;
								display: block;
								transition: all 0.5s;

								&:before {
									position: absolute;
									left: 0;
									top: 0;
									width: 100%;
									height: 100%;
									background: $color-accent-2;
									content: "";
									z-index: 99;
									display: flex;
									justify-content: center;
									flex-direction: column;
									text-align: center;
									opacity: 0;
									transition: all 0.5s;
								}

								&:after {
									position: absolute;
									left: 0;
									top: 0;
									width: 100%;
									height: 100%;
									display: flex;
									justify-content: center;
									flex-direction: column;
									text-align: center;
									color: $color-light-2;
									z-index: 999;
									opacity: 0;
									transition: all 0.5s;
								}

								&:hover:after {
									opacity: 1;
								}

								&:hover:before {
									opacity: 0.6;
								}

								img {
									width: 100%;
									max-width: 100%;
									height: 200px;
									max-height: 200px;
									vertical-align: middle;
									border-style: none;
								}
							}
						}
					}
				}

				.menu-btn-holder {
					width: 45px;
					height: 45px;
					position: absolute;
					right: 15px;
					top: 17px;
					cursor: pointer;
					margin-right: 30px;

					.menu-btn {
						background: transparent;
						width: 100%;
						height: 100%;
						border: 0;
						outline: 0;

						span {
							transition: all 0.3s;
						}

						&:hover {
							span {
								background-color: $color-text-accent;
							}
						}

						span {
							background-color: $color-light-2;
							width: 27px;
							height: 2px;
							position: absolute;
							top: 12px;
							left: 9px;
						}

						span:nth-child(2) {
							top: 22px;
						}

						span:nth-child(3) {
							width: 18px;
							top: 32px;
						}
					}
				}

				@media only screen and (max-width: 600px) {
					.navigation-container-holder {
						width: calc(100% - 30px) !important;
						padding: 30px 30px !important;
						ul.navbar-nav li a img {
							height: auto;
						}
					}
				}
			}
		}
	}
}
