.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	.popup-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $color-dark-2;
		border: 1px solid $color-accent-2-rgba;
		border-radius: 2px;
		width: 50%;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 30px 60px 60px;
		text-align: center;

		button {
			background-color: transparent;
			width: 40px;
			height: 40px;
			color: $color-text-light;
			border: 1px solid $color-accent-1-rgba;
			border-radius: 50%;
			outline: none;
			position: absolute;
			right: calc(50% - 20px);
			top: -60px;

			z-index: 9999;
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				background-color: $color-accent-2;
			}

			.fa-icon {
				transition: all 0.3s;
				font-size: 24px;

				&:hover {
					color: $color-text-light;
				}
			}
		}

		.popup-content {
			width: 100%;
			li {
				list-style: none;
			}
		}
	}

	@media only screen and (max-width: 599px) {
		.popup-container {
			width: 90%;
			padding: 10px;

			h3 {
				margin: 15px;
                width: 100%
			}
			p {
				font-size: 4vw;
			}

			a {
				margin: 15px auto 30px;
			}

            .close-popup {
                position: unset;
                top: unset;
                right: unset;
                margin: auto;
            }
		}
	}
}
