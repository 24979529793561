/* Color Theme Swatches in Hex */
$color-accent-1: #F91459;
$color-accent-2: #BF1140;
$color-accent-3: #8C0B47;
$color-accent-4: #EDC239;
$color-accent-5: #B18C00;
$color-light-1: #F2F2F2;
$color-light-2: #CDCDD4;
$color-med-1: #9E9EA4;
$color-med-2: #47474D;
$color-dark-1: #28282E;
$color-dark-2: #1C1D21;
$color-bg: #070707;

$color-text-light: #CDCDD4;
$color-text-accent: #c71047;
$color-text-dark: #212126;


/* Color Theme Swatches in RGBA */
$color-accent-1-rgba: rgba(249, 20, 89, 0.6);
$color-accent-2-rgba: rgba(191,17,64, 0.6);
$color-accent-3-rgba: rgba(140,11,71, 0.6);
$color-dark-rgba: rgba(33,33,38, 0.6);
$color-light-rgba: rgba(242,242,242, 0.6);
