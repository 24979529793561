*,
:after,
:before {
	box-sizing: border-box;
}

body {
	margin: 0;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
}

.App {
	width: 100vw;
	text-align: center;
	background-color: $color-bg;
	box-sizing: border-box;
	object-fit: cover;
}

.btn {
    height: 50px;
    line-height: 47px;
    color: $color-light-2;
    border: 1px solid $color-accent-2-rgba;
    padding: 0 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    transition: all 0.3s;
    text-decoration: none;
	white-space: nowrap;
    margin-top: 30px;
	cursor: pointer;

    &:hover {
        background-color: $color-accent-2;
        color: $color-text-light;
        
    }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-accent-2; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-accent-3; 
}


