.footer {
	padding: 40px 0 20px;
	background: $color-bg;
	text-align: center !important;

	.footer-container {
		width: 100%;
		height: 70vh;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;

		.footer-row {
			display: flex;
			flex-wrap: wrap;

			.footer-col {
				flex: 0 0 100%;
				max-width: 100%;
				color: $color-light-1;

				p,
				a {
					font-size: 16px;
				}

				.footer-menu {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: center;
					margin-bottom: 15px;

					.fa-icon {
						opacity: 0.7;
						transition: all 0.3s;

						&:hover {
							opacity: 0.9;
							color: $color-accent-2;
						}
					}

					ul {
						display: flex !important;
						padding: 15px 0 0 0;

						li {
							width: 20px;
							height: 20px;
							margin: 0 5px;
							transition: all 0.3s;
							display: inline-block;

							a {
								margin: 0;
							}
						}
					}
				}

				.footer-sub {
					p {
						font-size: 14px;
						color: $color-light-2;

						em {
							color: $color-accent-2;
						}
					}

					a {
						cursor: pointer;
						opacity: 0.6;
						transition: all 0.3s;

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
